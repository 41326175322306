<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Peminjaman Arsip </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updatePeminjaman">
            <CRow>
              <CCol sm="6">
              <CInput
                v-model="namadesa"
                label="Kelurahan"
                readonly
                placeholder="Input Kelurahan"
              />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="tipe_hak"
                  label="Tipe Hak"
                  readonly
                  placeholder="Input Tipe Hak"
                />
              </CCol>
              <CCol sm="6">
              <CInput
                v-model="nib"
                label="NIB"
                readonly
                placeholder="Input NIB"
              />
              </CCol>
              <CCol sm="6">
                <CInput
                v-model="nomor_hak"
                label="Nomor Hak"
                readonly
                placeholder="Input Nomor Hak"
              />
              </CCol>
              
              <CCol sm="6">
              <CInput
                v-model="su"
                label="SU"
                readonly
                placeholder="Input SU"
              />
              </CCol>

              <CCol sm="6">
              <CInput
                v-model="ruangan"
                label="Ruangan"
                readonly
                placeholder="Input Ruangan"
              /> 
              </CCol> 
              
              <CCol sm="6">
              <CInput
                v-model="lemari"
                label="Lemari"
                readonly
                placeholder="Input Lemari"
              />
              </CCol> 
              <CCol sm="6">
              <CInput
                v-model="kolom"
                label="Kolom"
                readonly
                placeholder="Input Kolom"
              />
              </CCol> 
              <CCol sm="6">
                <CInput
                v-model="baris"
                label="Baris"
                readonly
                placeholder="Input Baris"
              />
              </CCol> 
              <CCol sm="6">
                <CInput
                v-model="nama_peminjam"
                label="Nama Peminjam"
                placeholder="Input Peminjam"
                />
              </CCol> 
              <CCol sm="6">
                <CInput
                v-model="tgl_pinjam"
                label="Tgl Pinjam"
                readonly
                placeholder="Input Tgl Pinjam"
                />
              </CCol> 
              <CCol sm="6">
                <div class="form-group">
                  <label>Tanggal Kembali</label>
                  <datepicker v-model="tgl_kembali" input-class="form-control"  format="yyyy-MM-dd" readonly></datepicker>
                </div>
              </CCol>

              <CCol sm="6">
              <div class="form-group">
                <label> Status Pengembalian</label>
                <select v-model="pengembalian" placeholder='Pilih Status' class="form-control">
                  <option value="" disabled selected>Pilih Status</option>
                  <option value="1">Sudah Kembali</option>
                  <option value="0">Belum Kembali</option>
                </select>
                </div> 
              </CCol>
            </CRow>           
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/peminjaman">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>  
  
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  components: {Datepicker},
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nib : "",
      tgl_kembali : "",
      tgl_pinjam : "",
      tipe_hak : "",
      id_arsip : "",
      namadesa : "",
      su : "",
      nomor_hak : "",
      lemari : "",
      kolom : "",
      baris : "",
      ruangan : "",
      file : "",
      filename : "",
      pengembalian : "0",
      myerror : false,
      placeholder : "Pilih File ",
      ruangans: [],
      lemaris: [],
      koloms: [],
      bariss: [],
      selected: [], // Must be an array reference!
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.showArsip();
  // this.loadData();
  },

  methods: {
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.filename = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },
    loadData: function(event){

     //
    },

    updatePeminjaman: function(event){
      // Simple POST request with a JSON body using axios
      const peminjaman = { 
                          id_arsip:this.id_arsip,
                          nama_peminjam:this.nama_peminjam,
                          tgl_kembali:this.tgl_kembali,
                          pengembalian:this.pengembalian,
                          id_peminjaman: this.$route.params.id_peminjaman
                          };

  
      // alert(JSON.stringify(peminjaman));
      axios.post("http://139.180.219.157/earsip/api/public/updatepeminjaman", peminjaman)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/peminjaman');
            }
            else{
              this.myError = true;
            }
            
        })
    },
    showArsip: function(event){
      // Simple POST request with a JSON body using axios
      const peminjaman = { 
                          nib:this.nib,
                          tipe_hak: this.tipe_hak, 
                          namadesa: this.namadesa, 
                          su: this.su, 
                          nomor_hak: this.nomor_hak, 
                          lemari: this.lemari, 
                          kolom: this.kolom, 
                          baris: this.baris,
                          ruangan: this.ruangan
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get("http://139.180.219.157/earsip/api/public/editpeminjaman/"+this.$route.params.id_peminjaman+"?key=$2y$10$AnZ411EfYcBHTEMMYbXni.7HuKm5nf4fCleVEKMPs9dsDRKlrLCPu")
        .then((response) => {
              // alert(JSON.stringify(response));            
              this.id_arsip=response.data.id_arsip;
              this.pengembalian=response.data.pengembalian;
              this.nib=response.data.nib;
              this.tipe_hak=response.data.tipe_hak;
              this.namadesa=response.data.namadesa;
              this.su=response.data.su;
              this.nomor_hak=response.data.nomor_hak;
              this.lemari=response.data.rak;
              this.kolom=response.data.gedung;
              this.baris=response.data.subrak;
              this.ruangan=response.data.ruangan;
              this.tgl_pinjam=response.data.tgl_pinjam;
              this.tgl_kembali=response.data.tgl_kembali;
              this.nama_peminjam=response.data.nama_peminjam;
              // this.placeholder=response.data.file;
              
        })
    }
  }
}
</script>